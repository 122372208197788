// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-mdx-layout-js": () => import("./../src/components/mdx-layout.js" /* webpackChunkName: "component---src-components-mdx-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-privacy-statement-mdx": () => import("./../src/pages/privacy-statement.mdx" /* webpackChunkName: "component---src-pages-privacy-statement-mdx" */),
  "component---src-pages-what-is-quidditch-mdx": () => import("./../src/pages/what-is-quidditch.mdx" /* webpackChunkName: "component---src-pages-what-is-quidditch-mdx" */)
}

